import React from 'react';
import { Link } from 'react-router-dom';

import '@app/scss/pages/notFoundPage.scss';

const NotFoundPage: React.FC = () => {
	return <div className="not-found-page">
		<div className="container">
			<h1>404</h1>
			<p>
				The link you clicked may be broken or the
				{' '}
				<br />
				{' '}
				page may have been removed.
			</p>
			<small>
				<Link to="/">Visit the home page</Link>
			</small>
			<div className="circle small" />
			<div className="circle medium" />
			<div className="circle big" />
		</div>
	</div>;
};

export default NotFoundPage;
